const zIndexBase = {
  hidden: -1,
  0: 0,
  1: 100,
  2: 200,
  3: 300,
  4: 400,
  5: 500,
  6: 600,
  7: 700,
  8: 800,
  9: 900,
  10: 1000,
};

const zIndex = {
  tooltip: zIndexBase['1'],
  pricingHead: zIndexBase['2'],
  nav: zIndexBase['5'],
  navMenu: zIndexBase['5'],
  banner: zIndexBase['6'],
};

export default zIndex;
