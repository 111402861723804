import { css } from 'styled-components';
import { media } from 'src/utils/mixins';

/* ************************************************************
Font types
************************************************************ */
const avenirBold = css`
  font-family: 'Avenir Next', sans-serif;
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
`;

const avenirDemiBold = css`
  font-family: 'Avenir Next', sans-serif;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
`;

const avenirMedium = css`
  font-family: 'Avenir Next', sans-serif;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
`;

const avenirRegular = css`
  font-family: 'Avenir Next', sans-serif;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
`;

const aktivGrotesk = css`
  font-variant-ligatures: common-ligatures;
  font-family: aktiv-grotesk, sans-serif;
`;

/* ************************************************************
Text styles
************************************************************ */
const h1 = css`
  ${aktivGrotesk}
  font-size: 40px;
  font-weight: 700;
  letter-spacing: -0.025em;
  line-height: 1.1;

  ${media.small`
    font-size: 60px;
  `}

  ${media.medium`
    font-size: 50px;
  `}

  ${media.large`
    font-size: 76px;
  `}

  ${media.xLarge`
    font-size: 90px;
  `}
`;

const h2 = css`
  ${aktivGrotesk}
  font-size: 31px;
  font-weight: 700;
  letter-spacing: -0.025em;
  line-height: 1.2;

  ${media.small`
    font-size: 40px;
  `}

  ${media.medium`
    font-size: 42px;
  `}

  ${media.large`
    font-size: 60px;
  `}

  ${media.xLarge`
    font-size: 70px;
  `}
`;

const h3 = css`
  ${aktivGrotesk}
  font-size: 24px;
  font-weight: 700;
  letter-spacing: -0.025em;
  line-height: 1.2;

  ${media.small`
    font-size: 29px;
  `}

  ${media.medium`
    font-size: 29px;
  `}

  ${media.large`
    font-size: 50px;
  `}

  ${media.xLarge`
    font-size: 50px;
  `}
`;

const h4 = css`
  ${aktivGrotesk}
  font-size: 20px;
  font-weight: 700;
  letter-spacing: -0.025em;
  line-height: 1.2;

  ${media.small`
    font-size: 24px;
  `}

  ${media.medium`
    font-size: 24px;
  `}

  ${media.large`
    font-size: 42px;
  `}

  ${media.xLarge`
    font-size: 42px;
  `}
`;

const h5 = css`
  ${aktivGrotesk}
  font-size: 18px;
  font-weight: 700;
  letter-spacing: -0.025em;
  line-height: 1.2;

  ${media.small`
    font-size: 18px;
  `}

  ${media.medium`
    font-size: 29px;
  `}

  ${media.large`
    font-size: 29px;
  `}

  ${media.xLarge`
    font-size: 36px;
  `}
`;

const h6 = css`
  ${aktivGrotesk}
  font-size: 18px;
  font-weight: 700;
  letter-spacing: -0.025em;
  line-height: 1.2;

  ${media.small`
    font-size: 18px;
  `}

  ${media.medium`
    font-size: 24px;
  `}

  ${media.large`
    font-size: 24px;
  `}

  ${media.xLarge`
    font-size: 29px;
  `}
`;

const caps = css`
  ${aktivGrotesk}
  font-size: 17px;
  font-weight: 700;
  letter-spacing: 0.05em;
  line-height: 1.4;
  text-transform: uppercase;

  ${media.xLarge`
    font-size: 20px;
  `}
`;

const smallCaps = css`
  ${aktivGrotesk}
  font-size: 15px;
  font-weight: 700;
  letter-spacing: 0.05em;
  line-height: 1.4;
  text-transform: uppercase;

  ${media.xLarge`
    font-size: 17px;
  `}
`;

const p = css`
  font-family: 'Avenir Next', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.6;

  ${media.small`
    font-size: 16px;
  `}

  ${media.medium`
    font-size: 20px;
  `}

  ${media.large`
    font-size: 20px;
  `}

  ${media.xLarge`
    font-size: 25px;
  `}
`;

const small = css`
  font-family: 'Avenir Next', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.6;

  ${media.xLarge`
    font-size: 20px;
  `}
`;

export default {
  aktivGrotesk,
  avenirBold,
  avenirDemiBold,
  avenirMedium,
  avenirRegular,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  caps,
  smallCaps,
  p,
  small,
};
