import React from 'react';
import Cookies from 'js-cookie';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';
import { isNumber } from 'typical';

import { getEmployeeCount } from 'src/utils/reveal';
import theme from './src/theme';
import GlobalState from './src/components/GlobalState';
import Breakpoints from './src/components/Breakpoints';
import { getDefaultTrackingData } from './src/utils/analytics';
import isWindowDefined from './src/utils/isWindowDefined';
import navigate from 'src/utils/navigate';

export const wrapRootElement = ({ element }) => (
  <GlobalState>
    <Breakpoints>
      <ThemeProvider theme={theme}>{element}</ThemeProvider>
    </Breakpoints>
  </GlobalState>
);

wrapRootElement.propTypes = {
  element: PropTypes.element.isRequired,
};

export const onPreRouteUpdate = async ({ location }) => {
  if (location.pathname === '/blog') {
    navigate('https://blog.frame.io');
  }
};

export const onRouteUpdate = async ({ location, prevLocation }) => {
  if (prevLocation) {
    const isFromRollbackExperiment =
      Cookies.get('FIO_DINOSAUR_REWRITE') === 'true';
    if (window.location.hash === '' && !isFromRollbackExperiment) {
      // Used to force page relaod on Dinosaur, which redirects to Falkor
      navigate(`${location.origin}${location.pathname}`);
    }
  }
  if (isWindowDefined && window.analytics) {
    window.analytics.track('page-viewed', getDefaultTrackingData());

    const employeeCount = await getEmployeeCount();
    if (isNumber(employeeCount) && employeeCount > 10) {
      window.analytics.track(
        'page-viewed-with-employees-10-plus',
        getDefaultTrackingData()
      );
    }
  }
};
