import { css } from 'styled-components';

// DEPRECATED
const BREAKPOINTS = {
  xSmallOnly: '(max-width: 639px)',
  xSmall: '(min-width: 250px)',
  small: '(min-width: 640px)',
  smallOnly: '(min-width: 640px) and (max-width: 959px)',
  xSmallAndSmall: '(max-width: 959px)',
  medium: '(min-width: 960px)',
  mediumOnly: '(min-width: 960px) and (max-width: 1279px)',
  large: '(min-width: 1280px)',
  largeOnly: '(min-width: 1280px) and (max-width: 1919px)',
  xLarge: '(min-width: 1920px)',
};

// DEPRECATED
const media = {};
Object.keys(BREAKPOINTS).forEach((key) => {
  media[key] = (...args) => css`
    @media ${BREAKPOINTS[key]} {
      ${css(...args)};
    }
  `;
});

export { media };

export const listReset = () => css`
  margin: 0;
  padding: 0;
  list-style: none;
`;

export const gradientText = () => css`
  display: inline-block;
  background: rgb(47, 196, 209);
  background: linear-gradient(
    135deg,
    rgba(47, 196, 209, 1) 0%,
    rgba(79, 84, 241, 1) 28%,
    rgba(200, 109, 215, 1) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

export const gradientBG = () => css`
  background: rgb(47, 196, 209);
  background: linear-gradient(
    135deg,
    rgba(47, 196, 209, 1) 0%,
    rgba(79, 84, 241, 1) 28%,
    rgba(200, 109, 215, 1) 100%
  );
`;

export const darkGradientBG = () => css`
  background: #3e1fb3;
  background: radial-gradient(
      ellipse at bottom right,
      #dc83a3 0%,
      transparent 80%
    ),
    radial-gradient(ellipse at top right, #a939f2 0%, transparent 80%),
    radial-gradient(ellipse at bottom left, #001bb7 0%, #3e1fb3 100%);
`;

export default {};
