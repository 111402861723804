const colors = {
  gray100: '#F1F3F6',
  gray200: '#E3E6EC',
  gray300: '#B8C1CF',
  gray400: '#7D829C',
  gray500: '#666A72',
  gray600: '#323745',
  gray700: '#303247',
  gray800: '#20222B',
  gray900: '#15151C',
  green: 'rgb(54, 189, 166)',
  translucentGreen: 'rgba(54, 189, 166, 0.2)',
  purpleLight: '#5A52FF',
  purpleDark: '#17103b',
  red: '#FF4268',
  translucentGray: 'rgba(225, 230, 255, 0.6)',
  white: 'rgb(255, 255, 255)',
  white00: 'rgba(255, 255, 255, 0)',
  white10: 'rgba(255, 255, 255, 0.1)',
  white20: 'rgba(255, 255, 255, 0.2)',
  white30: 'rgba(255, 255, 255, 0.3)',
  white40: 'rgba(255, 255, 255, 0.4)',
  white50: 'rgba(255, 255, 255, 0.5)',
  white60: 'rgba(255, 255, 255, 0.6)',
  white70: 'rgba(255, 255, 255, 0.7)',
  white80: 'rgba(255, 255, 255, 0.8)',
  white90: 'rgba(255, 255, 255, 0.9)',
  whiteAlpha: [
    'rgba(255, 255, 255, 0)',
    'rgba(255, 255, 255, 0.1)',
    'rgba(255, 255, 255, 0.2)',
    'rgba(255, 255, 255, 0.3)',
    'rgba(255, 255, 255, 0.4)',
    'rgba(255, 255, 255, 0.5)',
    'rgba(255, 255, 255, 0.6)',
    'rgba(255, 255, 255, 0.7)',
    'rgba(255, 255, 255, 0.8)',
    'rgba(255, 255, 255, 0.9)',
  ],
  offWhite: 'rgba(235, 239, 255, 1)',
  offWhiteAlpha: [
    'rgba(235, 239, 255, 0)',
    'rgba(235, 239, 255, 0.1)',
    'rgba(235, 239, 255, 0.2)',
    'rgba(235, 239, 255, 0.3)',
    'rgba(235, 239, 255, 0.4)',
    'rgba(235, 239, 255, 0.5)',
    'rgba(235, 239, 255, 0.6)',
    'rgba(235, 239, 255, 0.7)',
    'rgba(235, 239, 255, 0.8)',
    'rgba(235, 239, 255, 0.9)',
  ],
  black: 'rgb(0, 0, 0)',
  blackAlpha: [
    'rgba(0, 0, 0, 0)',
    'rgba(0, 0, 0, 0.1)',
    'rgba(0, 0, 0, 0.2)',
    'rgba(0, 0, 0, 0.3)',
    'rgba(0, 0, 0, 0.4)',
    'rgba(0, 0, 0, 0.5)',
    'rgba(0, 0, 0, 0.6)',
    'rgba(0, 0, 0, 0.7)',
    'rgba(0, 0, 0, 0.8)',
    'rgba(0, 0, 0, 0.9)',
  ],
  black00: 'rgba(0, 0, 0, 0)',
  black10: 'rgba(0, 0, 0, 0.1)',
  black20: 'rgba(0, 0, 0, 0.2)',
  black30: 'rgba(0, 0, 0, 0.3)',
  black40: 'rgba(0, 0, 0, 0.4)',
  black50: 'rgba(0, 0, 0, 0.5)',
  black60: 'rgba(0, 0, 0, 0.6)',
  black70: 'rgba(0, 0, 0, 0.7)',
  black80: 'rgba(0, 0, 0, 0.8)',
  black90: 'rgba(0, 0, 0, 0.9)',
  success: '#1DC0A6',
  lightSuccess: '#D2F4EF',
  error: '#E0475F',
  lightError: '#FDDAE0',
};

export default colors;
