const ease = {
  long: 'cubic-bezier(0.7, 0, 0.05, 1)',
  medium: 'cubic-bezier(0.25, 0.1, 0.25, 1)',
  short: 'cubic-bezier(0.25, 0.1, 0.25, 1)',
  expo: 'cubic-bezier(0.16, 1, 0.3, 1)',
  cubic: 'cubic-bezier(0.33, 1, 0.68, 1)',
  inOutCubic: 'cubic-bezier(0.65, 0, 0.35, 1)',
};

export default ease;
