import React, { useEffect } from 'react';
import sync, { cancelSync } from 'framesync';
import debounce from 'lodash/debounce';
import Cookies from 'js-cookie';
import create from 'zustand';

export const useStore = create((set, get) => ({
  modal: {
    isOpen: false,
    type: null,
    data: null,
  },
  cookies: null,
  isLoggedIn: false,
  scroll: {
    y: 0,
    dy: 0,
    vy: 0,
  },
  reflow: {
    y: 0,
    width: 1920,
    height: 1080,
  },
  imagesLoading: [],
  imagesLoaded: [],
  allImagesLoaded: false,
  setIsLoggedIn: (value) =>
    set(() => {
      return { isLoggedIn: value };
    }),
  setImagesLoading: (img) =>
    set(() => {
      const imagesLoading = get().imagesLoading.concat(img);
      return { imagesLoading: Array.from(new Set(imagesLoading)) };
    }),
  setImagesLoaded: (img) =>
    set(() => {
      const imagesLoaded = get().imagesLoaded.concat(img);
      return { imagesLoaded: Array.from(new Set(imagesLoaded)) };
    }),
  setAllImagesLoaded: (loaded) =>
    set(() => ({
      allImagesLoaded: loaded,
    })),
  openModal: (type, data) =>
    set(() => ({ modal: { isOpen: true, type, data } })),
  closeModal: () => set(() => ({ modal: { isOpen: false } })),
  loadCookies: () => {
    const cookies = Cookies.get();
    set(() => ({ cookies }));
  },
  setCookie: (name, value, options) => {
    const stringValue = value.toString();
    Cookies.set(name, stringValue, options);
    set(() => ({ cookies: { [name]: stringValue } }));
  },
  setReflow: () => {
    const y = window.scrollY || window.pageYOffset;
    set(() => ({
      scroll: { y, dy: 0, vy: 0 },
      reflow: {
        y,
        width: window.innerWidth,
        height: window.innerHeight,
      },
    }));
  },
  setScroll: () => {
    const y = window.scrollY || window.pageYOffset;
    set((state) => ({
      scroll: {
        vy: y - state.scroll.y,
        dy:
          state.scroll.vy > 0 ? 1 : state.scroll.vy < 0 ? -1 : state.scroll.dy,
        y,
      },
    }));
  },
}));

export default ({ children }) => {
  const loadCookies = useStore((state) => state.loadCookies);
  const cookies = useStore((state) => state.cookies);
  const openModal = useStore((state) => state.openModal);
  const setReflow = useStore((state) => state.setReflow);
  const setScroll = useStore((state) => state.setScroll);
  const setIsLoggedIn = useStore((state) => state.setIsLoggedIn);
  const setAllImagesLoaded = useStore((state) => state.setAllImagesLoaded);
  const imagesLoaded = useStore((state) => state.imagesLoaded);
  const imagesLoading = useStore((state) => state.imagesLoading);

  useEffect(() => {
    if (!imagesLoaded.length) return;
    if (imagesLoaded.length === imagesLoading.length) setAllImagesLoaded(true);
  }, [imagesLoaded, imagesLoading, setAllImagesLoaded]);

  useEffect(() => {
    loadCookies();
  }, [loadCookies]);

  useEffect(() => {
    if (
      cookies &&
      typeof cookies['fio-marketing-consent'] !== 'undefined' &&
      typeof window.analytics.load === 'function'
    ) {
      window.analytics.load(process.env.SEGMENT_WRITE_KEY, {
        integrations: {
          All: false,
          'Segment.io': true,
          'Google Analytics': true,
          'Twitter Ads': true,
          "Google Ads (Classic)": true,
          "Google Universal Analytics": true,
          "Google Tag Manager": true,
          "Facebook Pixel": true,
          "Facebook Offline Conversions": true,
          "Clearbit Reveal": true,
          "Drift": true,
          "Personas Facebook Custom Audiences": true,
          "Zapier": true,
          "Personas": true,
          "Amazon S3": true,
          "Amplitude": true,
          "Salesforce Data Import Record Generator (Frame.io)": true,
          "Google Ads (Gtag)": true,
          "Geo Enrichment (Frame.io)": true,
          "Personas Data for Google (Frame.io)": true,
          "Adobe MPS Email Flow (Frame.io)": true,
          "Adobe MPS to Salesforce Flow (Frame.io)": true,
          "Google Analytics 4 Web": true
        },
      });
    }
  }, [cookies]);

  useEffect(() => {
    let params = new URL(window.location).searchParams;
    if (params.get('g') === '1') {
      openModal('blurGate');
    }
  }, [openModal]);

  useEffect(() => {
    window.addEventListener(
      'touchstart',
      function onFirstTouch() {
        document.body.classList.add('touch');
        window.removeEventListener('touchstart', onFirstTouch, false);
      },
      false
    );
  });

  useEffect(() => {
    let width = window.innerWidth;
    const resize = debounce(() => {
      if (window.innerWidth !== width) {
        width = window.innerWidth;
        setReflow();
      }
    }, 200);
    resize();
    window.addEventListener('resize', resize);
    return () => {
      window.removeEventListener('resize', resize);
    };
  }, [setReflow]);

  useEffect(() => {
    const read = sync.read(setScroll, true);
    return () => {
      cancelSync.read(read);
    };
  }, [setScroll]);

  return <>{children}</>;
};
