import { navigate as internalNavigate } from 'gatsby';
import isWindowDefined from 'src/utils/isWindowDefined';

export default function navigate(url, options = {}) {
  if (isWindowDefined) {
    const isRelativePath = /^\/(?!\/)/.test(url);
    const isInternal =
      isRelativePath && !options.isBlank && !options.isExternal;
    if (isInternal) {
      internalNavigate(url);
    } else if (options.isBlank) {
      window.open(url);
    } else {
      window.location = url;
    }
  }
}
