export default {
  shadow: {
    xl: 'rgba(0, 0, 0, 0.5) 0px 4rem 8rem -4rem',
    l: 'rgba(0, 0, 0, 0.5) 0px 2rem 4rem -2rem',
    m: 'rgba(0, 0, 0, 0.5) 0px 1rem 2rem -1rem',
  },
  topShadow: {
    xl: 'rgba(0, 0, 0, 0.5) 0px 4rem 8rem',
    l: 'rgba(0, 0, 0, 0.5) 0px 2rem 4rem',
    m: 'rgba(0, 0, 0, 0.5) 0px 1rem 2rem',
  },
  glow: {
    xl: '-4rem 2rem 8rem -2rem #4a1358bb, 4rem 2rem 8rem -2rem #2d027b',
  },
};
