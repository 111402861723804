const sidePadding = {
  xsm: '25px',
  sm: '60px',
  md: '80px',
  lg: '100px',
  xlg: '150px',
};

export default {
  sidePadding,
  navMargin: '6vw',
  pageMargin: '6vw',
  sectionMargin: '4rem',
  headerHeight: 'var(--header-height)',
};
