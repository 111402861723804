import mapKeys from 'lodash/mapKeys';
import isWindowDefined from 'src/utils/isWindowDefined';
import Cookies from 'js-cookie';
import { isDefined } from 'typical';
import {
  getHasEnterpriseEmailProvider,
  getHasEnterpriseEmployeeCount,
} from 'src/utils/reveal';
let hasRollbackExperimentTracked = false;

// window.analytics comes from Segment (https://segment.com/docs/sources/website/analytics.js/)

export function trackClick(trackingData) {
  if (isWindowDefined && window.analytics) {
    window.analytics.track('button-clicked', {
      position: 'middle',
      ...getDefaultTrackingData(),
      ...trackingData,
    });
  }
}

export async function trackSubmit(trackingData) {
  if (isWindowDefined && window.analytics) {
    const mergedData = {
      ...getDefaultTrackingData(),
      ...mapKeys(trackingData, (_, k) => `mktg_form_${k}`),
    };

    window.analytics.track('mktg-form-completed', mergedData);

    const hasEnterpriseEmailProvider = await getHasEnterpriseEmailProvider();
    if (hasEnterpriseEmailProvider) {
      window.analytics.track('mgl-email-captured-client', mergedData);
    }

    const hasEnterpriseEmployeeCount = await getHasEnterpriseEmployeeCount();
    if (hasEnterpriseEmployeeCount) {
      window.analytics.track('mql-email-captured-client', mergedData);
    }
  }
}

export function trackPageView() {
  if (isWindowDefined && window.analytics) {
    const path = window.location.pathname
      .split('/')
      .filter((x) => x !== '')
      .join('-');

    const pageName = path === '' ? 'home' : path;

    window.analytics.track(`page-viewed-${pageName}`, getDefaultTrackingData());

    const isFromRollbackExperiment =
      Cookies.get('FIO_DINOSAUR_REWRITE') === 'true';

    if (isFromRollbackExperiment && !hasRollbackExperimentTracked) {
      trackRollbackExperimentStarted();
      hasRollbackExperimentTracked = true;
    }
  }
}

export function trackRollbackExperimentStarted(trackingData) {
  if (isWindowDefined && window.analytics) {
    window.analytics.track('Experiment Shown', {
      ...getDefaultTrackingData(),
      ...trackingData,
      experimentName: 'Relaunch holdout',
      variant: 'a',
    });
  }
}

export function trackExperimentStarted(trackingData) {
  if (isWindowDefined && window.analytics) {
    window.analytics.track('fio_experiment_started', {
      ...getDefaultTrackingData(),
      ...trackingData,
    });
  }
}

export function getDefaultTrackingData() {
  if (!isWindowDefined) return {};

  return {
    client: 'marketing site',
    page: window.location.pathname,
    query_param: window.location.search,
  };
}
