import React, { createContext, useContext, useEffect, useState } from 'react';
import { isNull } from 'typical';
import theme from 'src/theme';
import { useStore } from 'src/components/GlobalState';

const BreakpointsContext = createContext();

export const useBreakpoint = (index) => {
  const matches = useContext(BreakpointsContext);
  return matches[index];
};

export const useResponsiveValue = (values) => {
  const matches = useContext(BreakpointsContext);
  const matchIndex = [...matches, false].indexOf(false);
  return values.reduce(
    (a, b, index) => (isNull(b) || index > matchIndex ? a : b),
    undefined
  );
};

export default ({ children }) => {
  const setReflow = useStore((state) => state.setReflow);
  const [matches, setMatches] = useState([]);

  useEffect(() => {
    const medias = theme.breakpoints.map((breakpoint) =>
      window.matchMedia(`screen and (min-width: ${breakpoint})`)
    );

    const handleChange = () => {
      setMatches(medias.map((media) => media.matches));
      setReflow();
    };
    handleChange();

    medias.forEach((media) => {
      media.addListener(handleChange);
    });

    return () => {
      medias.forEach((media) => {
        media.removeListener(handleChange);
      });
    };
  }, [setReflow]);

  return (
    <BreakpointsContext.Provider value={matches}>
      {children}
    </BreakpointsContext.Provider>
  );
};
