import color from './color';
import duration from './duration';
import ease from './ease';
import layout from './layout';
import shadow from './shadow';
import text from './text';
import zIndex from './zIndex';
import backgrounds from './backgrounds';
import range from 'lodash/range';

const theme = {
  color,
  duration,
  ease,
  layout,
  shadow,
  text,
  zIndex,
  backgrounds: { ...color, ...backgrounds },

  fonts: {
    avenir: 'Avenir Next',
    aktiv: 'aktiv-grotesk',
  },
  fontSizes: range(15)
    .map((n) => Math.floor(Math.pow(1.2, n) * 17))
    .map((x) => x / 16 + 'rem'),
  fontWeights: {
    regular: '400',
    medium: '500',
    demibold: '600',
    bold: '700',
  },
  lineHeights: {
    paragraph: 1.6,
    heading: 1,
  },
  letterSpacings: {
    caps: '0.05em',
  },
  breakpoints: [40, 60, 80, 90, 110].map((x) => x * 16 + 'px'),
  space: [0, 0.25, 0.5, 1, 2, 4, 8, 16, 32, 64, 128].map((x) => x + 'rem'),
  radii: [0, 0.5, 1].map((x) => x + 'rem'),
};

theme.space.navMargin = 'var(--nav-margin)';
theme.space.pageMargin = 'var(--page-margin)';
theme.space.pageMaxWidth = theme.breakpoints[4];
theme.space.sectionMargin = 'var(--section-margin)';
theme.space.headerHeight = 'var(--header-height)';

theme.colors = theme.color;
theme.sizes = theme.space;
theme.shadows = theme.shadow;

export const white = {
  ...theme,
  name: 'white',
  currentColor: {
    background: color.white,
    text: color.black,
    translucentText: color.black80,
    transparentText: color.black00,
    highlight: color.purpleLight,
  },
};

export const black = {
  ...theme,
  name: 'black',
  currentColor: {
    background: color.black,
    text: color.white,
    translucentText: color.white80,
    transparentText: color.white00,
    highlight: color.purpleLight,
  },
};

export const gradient = {
  ...theme,
  name: 'gradient',
  currentColor: {
    // background: `radial-gradient( ellipse at bottom right, #dc83a3 0%, transparent 80% ), radial-gradient(ellipse at top right, #a939f2 0%, transparent 80%), radial-gradient(ellipse at bottom left, #001bb7 0%, #000 100%)`,
    background: `radial-gradient( ellipse at bottom right,#dc83a3 0%,transparent 80% ),radial-gradient(ellipse at top right,#530089 0%,transparent 80%),radial-gradient(ellipse at bottom left,#2d4cff 0%,#000 100%)`,
    text: color.white,
    translucentText: color.white80,
    transparentText: color.black00,
    highlight: color.purpleLight,
  },
};

export default black;
